.signin {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.signin .left {
  flex: 360px 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 2rem;
}

.quote {
  font-size: 15px;
  margin-bottom: 1rem;
}

.signin .right {
  flex: 1;
}

label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

.field input {
  box-shadow: none;
  outline: none;
  background-color: rgb(245, 246, 249);
  border: 1px solid rgb(217, 218, 220);
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  width: calc(100% - 24px);
}

.field {
  position: relative;
}

.field .passwordBtn {
  position: absolute;
  right: 0;
  bottom: 14px;
  width: 48px;
  height: 48px;

}

.signinBtn {
  font-size: 19px;
  padding: 10px;
  margin-top: 10px;
  color: white;
  width: 100%;
  background-color: #2e3c67;
  border-radius: 8px;
}

.eye {
  margin-left: auto;
  margin-right: auto;
}

.signinBtn[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.error {
  display: none;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: 1px solid red;
  border-radius: 8px;
  background-color: rgb(252, 237, 238);
}

.error.active {
  display: block;
}