.audio .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0.5rem 1rem;
}

.audio .heading button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  background-color: #2e3c67;
  color: white;
}

.audio .right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.audio .right input {
  border-radius: 0.5rem;
  outline: none;
  border: 1px solid #dedede;
  background-color: #f1f1f1;
  padding: 0.75rem 1rem;
  flex: 1;
}

.audio .right select {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-left: 0.6rem;
  background-color: #f1f1f1;
  border: 1px solid #dedede;
}

.audio .messages {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.audio .message {
  border-bottom: 1px solid #dedede;
}

.audio .textWrapper {
  width: 60%;
  text-align: left;
  padding: 0.5rem 0.6rem;
}

.audio .text {
  font-size: 1rem;
  font-weight: 500;
}

.audio .duration {
  font-size: 0.9rem;
  opacity: 0.5;
}


.audio .btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0rem;
  margin: auto;
}

.audio .message .status {
  color: rgb(47, 60, 103);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: 1px;
  min-width: 72px;
  text-align: center;
}

.Draft {
  background-color: rgb(204, 208, 219);
}

.Published {
  background-color: rgb(210, 239, 221);
}

.Archived {
  background-color: rgb(243, 211, 217);
}

.audio .btns button {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
}

.audio .btns button.play {
  background-color: #f1f1f1;
  height: 2.4rem;
  width: 2.4rem;
  padding: 0.5rem;
}

.btns img {
  height: 100%;
  width: 100%;
}

.primary {
  color: white;
  background-color: rgb(47, 60, 103);
}

.success {
  background-color: rgb(210, 239, 221);
}

.danger {
  background-color: rgb(243, 211, 217);
}

.switch {
  margin-top: 0.3rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked+.slider {
  background-color: #2196f3;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .audio .heading {
    padding: 1rem 0rem;
  }

  .audio .right {
    display: flex;
    flex-direction: column;
  }

  .audio .right select {
    margin-left: 0rem;
    margin-bottom: 0.5rem;
  }

  .audio .right input {
    margin-bottom: 0.5rem;
  }

  .audio .left h1 {
    margin: 0.4rem 0rem;
  }

  .audio .heading div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .audio .message {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid gray;
    margin-bottom: 0.6rem;
  }

  .audio .textWrapper {
    width: 100%;
    padding: 2px 0px;
  }

  .audio .btns {
    justify-content: space-between;
  }
}