.dashboard {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0rem;
}
.dashboard .userBox,
.messageBox {
  width: 34rem;
  margin-left: 1rem;
  padding: 0.8rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.dashboard .data {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
}

.data div {
  display: flex;
  align-items: center;
}
.dashboard .data h4 {
  margin: 0.5rem 0px;
}
.dashboard .userBox h3 {
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #a1a0a0;
}
.dashboard p {
  font-size: 1rem;
  text-transform: uppercase;
  color: #323c64;
}
.dashboard h1 {
  font-size: 2rem;
  color: #323c64;
  margin: 0.5rem 0;
}
