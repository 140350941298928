.chip {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  margin: 0.1rem;
  font-size: 0.7rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.loader {
  width: 18px;
  height: 18px;
  margin-left: 0.25rem;
  border-radius: 18px;
}

.dot {
  width: 10px;
  height: 10px;
  margin-left: 0.25rem;
  border-radius: 10px;
}

.dot.success {
  background: #008800;
}

.dot.danger {
  background: #880000;
}