.modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  z-index: 1;
  background: rgba(0, 0, 0, 0.25);
}

.modal .contain {
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 25rem;
  min-height: 12rem;
  padding: 1rem;
  background-color: #FFF;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.modal .contain h2 {
  font-size: 1.2rem;
  padding: 0.5rem;
}

.modal .contain p {
  font-size: 1rem;
  padding: 0 0.5rem 1rem;
  margin-bottom: auto;
}

.modal .btns {
  padding: 0.5rem;
  text-align: right;
}

.modal .contain button {
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
}

.modal .contain .danger {
  background-color: rgb(235, 68, 90);
}

.modal .contain .success {
  background-color: rgb(40, 185, 98);
}

.modal .loading::after {
  content: '';
  background: #18d63e url('../../images/loader.svg') no-repeat center center / auto 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}