.users h1 {
  margin: 2rem 0 1rem;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  padding: 0.5rem;
}

.user button {
  color: #323c64;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  background-color: rgba(50, 60, 100, 0.2);
  text-transform: uppercase;
}

.user div:nth-child(2) {
  display: flex;
  align-items: center;
}

.user .danger {
  background-color: rgb(235, 68, 90);
  color: white;
}

.inputWrapper {
  width: calc(100% - 2rem);
  display: flex;
  align-items: center;
}

.activeSelect {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-left: 0.6rem;
  background-color: #f1f1f1;
  border: 1px solid #dedede;
}

.searchBox {
  flex: 1;
  border-radius: 0.5rem;
  outline: none;
  border: 1px solid #dedede;
  background-color: #f1f1f1;
  padding: 0.75rem 1rem;
}

.user h2 {
  margin: 0.25rem 0;
  line-height: 1;
  font-size: 1.2rem;
}

.user p {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(46, 45, 45);
  margin: 0;
}

.loadbtn {
  display: flex;
  margin: auto;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  color: white;
  padding: 1rem 1.5rem;
  background-color: #323c64;
  text-transform: uppercase;
}

.disable {
  opacity: 0.7;
  background-color: rgb(188, 182, 182);
  cursor: not-allowed;
}