@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.4;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  margin: 0;
  padding: 0;
}

button {
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0.3rem;
}

.container {
  width: calc(100% - 2rem);
  max-width: 1200px;
  margin: auto;
  padding: 0 1rem;
}


.loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.loading::after {
  content: '';
  background: #2e3c67 url('../src/images/loader.svg') no-repeat center center / auto 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}



@media only screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}