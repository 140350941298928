header.navbar {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.navbar nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
}

.navbar .logodiv {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 180px;
}

.navbar h2 {
  font-weight: 700;
  font-size: 1.25rem;
  color: rgb(47, 60, 103);
  margin-left: 0.5rem;
}

.navbar .logoImg {
  margin-left: 25px;
}

.navbar .list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-self: stretch;
  list-style-type: none;
  background-color: white;
  position: relative;
  margin: 0;
}

.navbar .item {
  font-size: 1rem;
  color: rgb(47, 60, 103);
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-bottom: 3px solid transparent;
  border-radius: 0;
}

.navbar .item.active {
  font-weight: 600;
  border-bottom-color: rgb(47, 60, 103);
}

.navbar .btn {
  width: 50px;
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

@media screen and (max-width: 500px) {
  .navbar nav {
    display: flex;
    flex-direction: column;
  }

  .navbar .list {
    top: -20px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    height: auto;
    text-align: right;
    border: 1px solid #d7d8da;
    text-align: left;
  }

  .navbar .items {
    margin-top: 5px;
    /* margin-bottom: 8px; */
    width: 100%;
    text-align: left;
    margin-left: -20px;
  }

  .navbar .btn {
    display: block;
  }
}