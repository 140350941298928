.userDetails {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.2s ease-in;
  display: flex;
}

.userDetails .right {
  width: 480px;
  height: 100vh;
  height: 100svh;
  background-color: white;
  margin-left: auto;
  box-shadow: 0 4px 6px 5px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  display: flex;
  flex-direction: column;
}

.userDetails .right .header {
  display: flex;
  align-items: center;
  height: 70px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.userDetails .content {
  flex: 1;
  overflow-y: auto;
}

.userDetails .userContainer {
  padding-top: 1rem;
  text-align: center;
}

.userDetails .userImage {
  height: 150px;
  width: 150px;
  border: 1px solid gray;
  background-color: #f1f1f1;
  border-radius: 50%;
  margin: auto;
  display: flex;
}

.userContainer h1 {
  margin: auto;
}

.userContainer h2 {
  margin-bottom: 0px;
}

.userDetails h4,
.userDetails p {
  margin-top: 0;
  margin-bottom: 0.4rem;
}

.userDetails .right .footer {
  padding-left: 1rem;
  height: 5rem;
  box-shadow: 4px 4px 7px 0px rgb(0 0 0 / 10%), 6px 4px 3px 8px rgb(0 0 0 / 10%);
}

.userDetails .messagesWrapper {
  padding: 1rem 1.5rem 0;
  text-align: left;
}

.tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  margin: 1.5rem 0;
}

.tab {
  flex: 120px 0 1;
  text-align: center;
  padding: 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: #A1A1A1;
  cursor: pointer;
}

.tab:hover {
  color: #666666;
  font-weight: 600;
}

.tab.active {
  font-weight: 600;
  color: #E7185C;
  border-bottom: 2px solid #E7185C;
}

.panel {
  margin-top: 1.5rem;
}

.userDetails .filter {
  display: flex;
  align-items: center;
}

.userDetails .checkbox {
  display: flex;
  align-items: center;
  flex: 1;
}

.filter label {
  margin: 0 0.25rem;
  flex: 1;
}

.messages {
  margin-top: 0.5rem;
}

.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid rgb(244, 240, 240);
}

.stats p {
  font-weight: 600;
}

.userDetails .message {
  background: radial-gradient(350.23% 3373.88% at 195.96% 177.83%,
      #ffbe00 0%,
      rgba(236, 210, 135, 0.520833) 47.92%,
      rgba(255, 190, 0, 0) 100%);
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.userDetails .message .statuses {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userDetails .text {
  flex: 1;
}

.userDetails .message .sphere {
  display: inline-block;
  padding: 0.4rem 0.75rem 0.3rem;
  border-radius: 0.5rem;
  border-color: transparent;
  text-transform: uppercase;
  background-color: #ffbe00;
  line-height: 1;
  font-size: 0.7rem;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.userDetails .message p {
  font-size: 1rem;
}

.userDetails .message p:last-child {
  letter-spacing: 1px;
  font-size: 0.75rem;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 0.5rem;
}

.userDetails .message button.btn {
  background: rgba(255, 255, 255, 0.25);
  height: 3rem;
  flex-basis: 3rem;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 3rem;
  display: flex;
  align-self: flex-end;
}

.btn img {
  margin: auto;
  height: 1rem;
  width: 1rem;
  opacity: 0.8;
}

.deleteBtn {
  position: absolute;
  top: -1rem;
  right: -1rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 2.4rem;
  background: #AA0000;
  display: flex;
}

.deleteBtn img {
  display: block;
  margin: auto;
  width: 1rem;
  height: 1rem;
}

.statuses {
  display: flex;
}

.userDetails .footer button {
  padding: 0.5rem 2rem;
  border-radius: 0.4rem;
  margin-right: 0.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-top: 1rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}