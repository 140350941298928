.messageForm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.50);
  animation: fadeIn .2s ease-in;
  display: flex;
}

.messageForm .right {
  margin-left: auto;
  max-width: 480px;
  background-color: white;
  box-shadow: 0 4px 6px 5px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.messageForm .right .header {
  display: flex;
  align-items: center;
  height: 70px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.header img {
  height: 2rem;
  width: 2rem;
}

.messageForm .right .formContainer {
  margin: 1.5rem;
}

.formContainer h5 {
  margin: 0px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.formContainer .textField {
  width: calc(100% - 2rem);
  border: 1px solid #F1F1F1;
  background: #FAFAFA;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 1rem;
}

.messageForm .sphere {
  padding: 0.5rem 1rem;
  width: fit-content;
  border: 1px solid black;
  border-radius: 1rem;
  display: inline-flex;
  margin: 0 0.5rem 1rem 0;
}

.messageForm .sphere.active {
  color: white;
  background-color: rgb(47, 60, 103);
}

.messageForm .right .footer {
  padding-left: 1rem;
  width: 100%;
  position: fixed;
  bottom: 0px;
  height: 5rem;
  box-shadow: 4px 4px 7px 0px rgb(0 0 0 / 10%), 6px 4px 3px 8px rgb(0 0 0 / 10%);
}

.footer button {
  padding: 0.5rem 2rem;
  border-radius: 0.4rem;
  margin-right: 0.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-top: 1rem;
}

.save {
  color: white;
  background-color: rgb(47, 60, 103);
}

.save:disabled {
  opacity: 0.8;
  background-color: rgb(155, 153, 153);
  cursor: not-allowed;
}

.messageForm .btns {
  display: flex;
}

.messageForm .btn {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(47, 60, 103);
  border-radius: 2rem;
}

.messageForm .btn:first-child {
  margin-right: 0.25rem;
}

.messageForm .btn:last-child {
  margin-left: 0.25rem;
}

.messageForm .btn .btnTxt {
  font-size: 1rem;
  color: rgb(47, 60, 103);
  padding-right: 0.5rem;
  flex: 1;
  text-align: left;
}

.messageForm .btn img {
  flex-basis: 24px;
}

.messageForm .btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes slide-left {
  0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}