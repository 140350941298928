.loader.overlay {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  display: flex;
}

.loader.overlay img {
  margin: auto;
  width: 80px;
  height: 80px;
}